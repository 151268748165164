import {IconType, KnowledgeItemData, SkillItemData, WorkItemData} from "../interfaces"
import Translation from '../data/translations'

class DataService {
    private static _instance: DataService;

    //Singleton
    static getInstance() {
        if (!DataService._instance) {
            DataService._instance = new DataService();
        }
        return DataService._instance;
    }

    getKnowledgeData(): KnowledgeItemData[]{

        return [
            {
                icon: IconType.code,
                title: Translation.knowWebAppTitle,
                text: Translation.knowWebAppText
            },
            {
                icon: IconType.phone,
                title: Translation.knowMobileTitle,
                text: Translation.knowMobileText
            },
            {
                icon: IconType.desktop,
                title: Translation.knowDesktopTitle,
                text: Translation.knowDesktopText
            },
            {
                icon: IconType.cloud,
                title: Translation.knowCloudTitle,
                text: Translation.knowCloudText
            }
        ]
    }

    getSkillData(): SkillItemData[] {
        return [
            {
                id: 1,
                title: 'C#',
                percent: 95
            },
            {
                id: 2,
                title: 'TypeScript',
                percent: 80
            },
            {
                id: 3,
                title: 'React',
                percent: 65
            },
            {
                id: 4,
                title: 'HTML & CSS',
                percent: 70
            },
            {
                id: 5,
                title: 'PowerShell',
                percent: 95
            }
        ]
    }
    getWorkData(): WorkItemData[] {
        return [
            {
                employer: 'Notino',
                position: Translation.workFullStackTitle,
                text: Translation.workNotinoText,
                year: 2020,
                tags: ['C#','ASP.NET MVC','ASP.NET Core','MS SQL']
            },
            {
                employer: 'Oriflame Software',
                position: Translation.workMobDevTitle,
                text: Translation.workMobDevText,
                year: 2019,
                tags: ['iOS','Android','Swift','Kotlin']
            },
            {
                employer: 'Oriflame Software',
                position: Translation.workPlatformArchTitle,
                text: Translation.workPlatformArchText,
                year: 2017,
                tags: ['C#','TypeScript','React','PowerShell']
            },
            {
                employer: 'Oriflame Software',
                position: Translation.workDevTitle,
                text: Translation.workOriSeniorDevText,
                year: 2014,
                tags: ['C#','ASP.NET','TypeScript','SonarQube']
            },
            {
                employer: 'ELINK X',
                position: Translation.workElxLeadTitle,
                text: Translation.workElxLeadText,
                year: 2012,
                tags: ['JavaScript','C#','ExtJS','ASP.NET MVC']
            },
            {
                employer: 'EmbedIT',
                position: Translation.workDevTitle,
                text: Translation.workEmbedText,
                year: 2010,
                    tags: ['C#','WPF','VB6','WCF','Oracle']
            },
            {
                employer: 'ELINK X',
                position: Translation.workDevTitle,
                text: Translation.workEmbedText,
                year: 2006,
                tags: ['C#','Win Forms','MS SQL','Infragistics']
            }
        ]
    }
}

const instance = DataService.getInstance()
export default instance

