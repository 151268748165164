import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Section from "./Section"
import {SectionType} from "../../interfaces"
import IconPanel from "../icons/IconPanel"
import Translation from '../../data/translations'

const SectionAboutMe: React.FC = () => {

    const { file } = useStaticQuery(
        graphql`
            query {
                file(relativePath: { eq: "jaroslav-cervenka.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 370) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )

    return (
        <Section type={SectionType.white} id='about' title={Translation.sectionTitleAboutMe} >
            <div className='clearfix mt4 mb4'>
                <div className='sm-col sm-col-12 md-col-8'>
                    <div className='clearfix'>
                        <div className='sm-col sm-col-12 p2'>
                            <p>
                                <span className='bold'>{Translation.aboutMeTitle}</span>
                            </p>
                            <p>
                                <span className='text-darkgray'>{Translation.aboutMeText}</span>
                            </p>
                        </div>
                        <div className='sm-col sm-col-12 p2'>
                            <IconPanel />
                        </div>
                    </div>
                </div>
                <div className='sm-col sm-col-12 md-col-4 p2 center'>
                    <div>
                        <Img
                            fluid={file.childImageSharp.fluid}
                            alt='Jaroslav Červenka'
                            className='circle'
                        />
                    </div>
                </div>
            </div>
        </Section>
)}

export default SectionAboutMe