import * as React from 'react'
import theme from "../../styles/theme"
import Translation from '../../data/translations'

const PhoneIcon: React.FC = () => {
    return(
        <svg className='icon-know' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <title>{Translation.iconPhone}</title>
            <circle cx="12" cy="12" r="12" fill={theme.color.black.regular}/>
            <path
                d="M15.850340083241463,6.564626023173332 c0,-0.6045714254379273 -0.4906666641235353,-1.0952380895614624 -1.0952380895614624,-1.0952380895614624 h-5.476190447807312 c-0.6045714254379273,0 -1.0952380895614624,0.4906666641235353 -1.0952380895614624,1.0952380895614624 v10.952380895614624 c0,0.6045714254379273 0.4906666641235353,1.0952380895614624 1.0952380895614624,1.0952380895614624 h5.476190447807312 c0.6045714254379273,0 1.0952380895614624,-0.4906666641235353 1.0952380895614624,-1.0952380895614624 v-10.952380895614624 zm-4.654761880636215,0 h1.6428571343421936 c0.1511428563594819,0 0.2738095223903658,0.12266666603088403 0.2738095223903658,0.2738095223903658 s-0.12266666603088403,0.2738095223903658 -0.2738095223903658,0.2738095223903658 h-1.6428571343421936 c-0.1511428563594819,0 -0.2738095223903658,-0.12266666603088403 -0.2738095223903658,-0.2738095223903658 s0.12266666603088403,-0.2738095223903658 0.2738095223903658,-0.2738095223903658 zm0.8214285671710968,10.952380895614624 c-0.30283333176374483,0 -0.5476190447807312,-0.24533333206176802 -0.5476190447807312,-0.5476190447807312 s0.2447857130169869,-0.5476190447807312 0.5476190447807312,-0.5476190447807312 c0.3022857127189638,0 0.5470714257359505,0.24533333206176802 0.5470714257359505,0.5476190447807312 s-0.2447857130169869,0.5476190447807312 -0.5470714257359505,0.5476190447807312 zm2.738095223903656,-1.6428571343421936 h-5.476190447807312 v-7.6798094840049735 h5.476190447807312 v7.6798094840049735 z"
                id="svg_1" className="" fill="#ffffff" fill-opacity="1"/>
        </svg>
    )
}

export default PhoneIcon