import * as React from 'react'
import {IconType} from "../../interfaces"
import LinkedinIcon from "./LinkedinIcon"
import GithubIcon from "./GithubIcon"
import CodeIcon from "./CodeIcon"
import PhoneIcon from "./PhoneIcon"
import CloudIcon from "./CloudIcon"
import DesktopIcon from "./DesktopIIcon"

type Props = {
    type: IconType
}

const Icon: React.FC<Props> = ({type}) => {

    let icon = undefined

    switch(type) {
        case IconType.linkedIn:
            icon = (<LinkedinIcon />)
            break
        case IconType.linkedInWhite:
            icon = (<LinkedinIcon whiteBackground={true} />)
            break
        case IconType.github:
            icon = (<GithubIcon />)
            break
        case IconType.githubWhite:
            icon = (<GithubIcon whiteBackground={true} />)
            break
        case IconType.code:
            icon = (<CodeIcon />)
            break
        case IconType.phone:
            icon = (<PhoneIcon />)
            break
        case IconType.cloud:
            icon = (<CloudIcon />)
            break
        case IconType.desktop:
            icon = (<DesktopIcon />)
            break
        default:
            throw `Icon type ${type} is not supported.`
    }

    return icon
}

export default Icon