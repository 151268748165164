import * as React from 'react'

import dataService from '../../services/DataService'
import {SkillItemData} from "../../interfaces"
import SkillsItem from "./SkillsItem"

const SkillsList: React.FC = () => {

    const skillDataItems = dataService.getSkillData()
    const skillItems = skillDataItems.map((item: SkillItemData) => (
        <div key={item.title} className='sm-col sm-col-12'>
            <SkillsItem title={item.title} percent={item.percent}  />
        </div>
    ))

    return(
    <div className='clearfix'>
        {skillItems}
    </div>
)}

export default SkillsList