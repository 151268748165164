import * as React from 'react'
import theme from "../../styles/theme"
import Translation from '../../data/translations'

const CodeIcon: React.FC = () => {
    return(
        <svg className='icon-know' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <title>{Translation.iconCode}</title>
            <circle cx="12" cy="12" r="12" fill={theme.color.black.regular}/>
            <path
                d="M19.714285235945994,11.045944038577996 v1.33731119113857 l-5.020408038061266,2.476943815778478 v-1.3994387406095778 l3.539387666833193,-1.7464744462405628 l-3.539387666833193,-1.7508673032738666 v-1.3950458835762742 l5.020408038061266,2.4775713667832346 zm-10.040816076122532,2.414816266307469 l-3.539387666833193,-1.7464744462405628 l3.539387666833193,-1.7508673032738666 v-1.3950458835762742 l-5.020408038061266,2.4775713667832346 v1.33731119113857 l5.020408038061266,2.476943815778478 v-1.3994387406095778 zm4.422351930527218,-6.766882484301829 h-1.3040509878864146 l-2.5171070800829685,10.040816076122532 h1.3009132328626258 l2.5202448351067557,-10.040816076122532 z"
                id="svg_1" className="selected" fill="#ffffff" fill-opacity="1"/>
        </svg>
    )
}

export default CodeIcon