import * as React from 'react'
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby'

import Section from "./Section"
import {SectionType} from "../../interfaces"
import SectionContentTitle from "./SectionContentTitle"
import SkillsList from "../skill/SkillsList"
import Translation from '../../data/translations'
import BackgroundImage from 'gatsby-background-image'
import theme from '../../styles/theme'

const SectionSkills: React.FC = () => {
    const backgroundImage = 'coffee'
    const type = SectionType.black

    const { file } = useStaticQuery(
        graphql`
            query {
                file(relativePath: { eq: "coffee.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1050) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )

    return (
    <Section type={type} title={Translation.sectionTitleSkills} id='skills'>
        <div className='clearfix'>
            <div className='sm-col sm-col-12 md-col-6'>
                <BackgroundImage
                    Tag="div"
                    fluid={file.childImageSharp.fluid}
                    className='skill-image'
                    backgroundColor={theme.color.black.regular}
                />
            </div>
            <div className='sm-col sm-col-12 md-col-6 p1 mt4'>
                <div className='clearfix'>
                    <div className='sm-col sm-col-12 p2'>
                        <SectionContentTitle text={Translation.skillsTitle} type={type} />
                    </div>
                    <div className='sm-col sm-col-12 p2'>
                        <span className='text-gray'>{Translation.skillsText}</span>
                    </div>
                    <div className='sm-col sm-col-12 p2'>
                        <SkillsList />
                    </div>
                </div>
            </div>
        </div>
    </Section>
)}

export default SectionSkills