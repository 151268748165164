import * as React from 'react'
import styled from 'styled-components';

type Props = {
    title: string,
    percent: number
}

const SkillsItem: React.FC<Props> = ({
    title,
    percent}) => {

    return (
    <div className='clearfix mt2'>
        <div className='sm-col sm-col-12'>
            <span className='text-white text-uppercase px2 bold'>{title}</span>
        </div>
        <div className='sm-col sm-col-12'>
            <div className='bg-skill-item fit'>
                <SkillItemPercent className='bg-primary' percent={percent}>
                    <p className='text-white text-small p1 m0 px2'>{percent}%</p>
                </SkillItemPercent>
                <div className='skill-item-per bg-primary'>

                </div>
            </div>
        </div>
    </div>
)}

const SkillItemPercent = styled.div<{percent: number}>`
    width: ${props => props.percent}%
`

export default SkillsItem