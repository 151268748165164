import * as React from 'react'
import theme from "../../styles/theme"
import Translation from '../../data/translations'

const CloudIcon: React.FC = () => {
    return(
        <svg className='icon-know' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <title>{Translation.iconCloud}</title>
            <circle cx="12" cy="12" r="12" fill={theme.color.black.regular}/>
            <path
                d="M12.136054245157894,7.648525958188976 c2.106251676112403,0 3.5933763753194805,1.7061065560632471 3.395743724563495,4.135646210264145 c1.0644104184234415,-0.02805895658881281 2.704029446917545,0.4580929651782264 2.704029446917545,2.2691156197909472 c0,1.1772562220958411 -0.9576643879225234,2.134920610018364 -2.134920610018364,2.134920610018364 h-7.929705122925352 c-1.1772562220958411,0 -2.134920610018364,-0.9576643879225234 -2.134920610018364,-2.134920610018364 c0,-1.7061065560632471 1.5121337692101495,-2.3380430566286825 2.704029446917545,-2.2691156197909472 c-0.10186621196373347,-2.572884323730703 1.3468299162630135,-4.135646210264145 3.395743724563495,-4.135646210264145 zm0,-1.219954634296208 c-2.4435691324953046,0 -4.432705163715272,1.9159387531621945 -4.56202035495067,4.3259591332143525 c-1.5676417050706277,0.2824194978395726 -2.757707450826578,1.6505986202027696 -2.757707450826578,3.2987573311369465 c0,1.852501112178792 1.5023741321357802,3.354875244314572 3.354875244314572,3.354875244314572 h7.929705122925352 c1.852501112178792,0 3.354875244314572,-1.5023741321357802 3.354875244314572,-3.354875244314572 c0,-1.6481587109341773 -1.1900657457559511,-3.0163378332973747 -2.757707450826578,-3.2987573311369465 c-0.12931519123539806,-2.410020380052159 -2.118451222455365,-4.3259591332143525 -4.56202035495067,-4.3259591332143525 z"
                id="svg_1" className="selected" fill="#ffffff" fill-opacity="1"/>
        </svg>
    )
}

export default CloudIcon