import * as React from 'react'
import KnowledgeItem from "./KnowledgeItem"
import dataService from '../../services/DataService'

const KnowledgeList: React.FC = () => {

    const knowledgeDataItems = dataService.getKnowledgeData()
    const knowledgeItems = knowledgeDataItems.map((item) => (
        <div key={item.title} className='sm-col sm-col-12 md-col-6 p1'>
            <KnowledgeItem icon={item.icon} title={item.title} text={item.text}/>
        </div>
    ))

    return (
    <div className='clearfix'>
        {knowledgeItems}
    </div>
)}

export default KnowledgeList