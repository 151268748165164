import * as React from 'react'
import Section from "./Section"
import {SectionType} from "../../interfaces"
import WorkList from "../work/WorkList"
import Translation from '../../data/translations'

const SectionStory: React.FC = () => (
    <Section type={SectionType.white} id='story' title={Translation.sectionTitleStory} titleOnRight={true}>
        <div className='clearfix mt4 mb4'>
            <div className='sm-col sm-col-12'>
                <WorkList />
            </div>
        </div>
    </Section>
)

export default SectionStory