import * as React from 'react'

import {SectionType} from "../../interfaces"
import {ColorClass} from "../Theme"

type Props = {
    title: string,
    type: SectionType
}

const SectionTitle: React.FC<Props> = ({

                                           title,
                                           type
                                       }) => {
    const titleParts = title.split(' ',2)
    let classNameTitle1 = ColorClass.textWhite
    let classNameTitle2 = ColorClass.textPrimary

    switch(type) {
        case SectionType.white:
            classNameTitle1 = ColorClass.textBlack
            classNameTitle2 = ColorClass.textPrimary
            break
        case SectionType.black:
            classNameTitle1 = ColorClass.textWhite
            classNameTitle2 = ColorClass.textPrimary
            break
        case SectionType.color:
            classNameTitle1 = ColorClass.textWhite
            classNameTitle2 = ColorClass.textBlack
            break
    }

    return (
    <div className='section-title text-uppercase mt4 left-align'>
        <h2>
            <span className={classNameTitle1}>{titleParts[0]}</span><br/>
            <span className={classNameTitle2}>{titleParts[1]}</span>
        </h2>
    </div>
)}

export default SectionTitle