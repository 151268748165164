import * as React from 'react'
import {IconType} from "../../interfaces"
import Links from "../../data/links"
import Icon from "./Icon"

type Props = {
    type: IconType
}

const IconLink: React.FC<Props> = ({type}) => {

    let url = ''
    let title = ''

    switch(type){
        case IconType.linkedIn:
            url = Links.linkedIn
            title = 'LinkedIn'
            break
        case IconType.linkedInWhite:
            url = Links.linkedIn
            title = 'LinkedIn'
            break
        case IconType.github:
            url = Links.github
            title = 'GitHub'
            break
        case IconType.githubWhite:
            url = Links.github
            title = 'GitHub'
            break
        default:
            throw `Icon type ${type} is not supported.`
    }

    url = `https://${url}`

    return (
    <a href={url} title={title}>
        <Icon type={type} />
    </a>
)}

export default IconLink