import * as React from 'react'
import {IconType} from "../../interfaces"
import Icon from "../icons/Icon"

type Props = {
    icon: IconType,
    title: string,
    text: string
}

const KnowledgeItem: React.FC<Props> = ({
    icon,
    title,
    text}) => {

    return (
    <div className='clearfix'>
        <div className='sm-col sm-col-3 p1'>
            <Icon type={icon} />
        </div>
        <div className='sm-col sm-col-9 p1'>
            <h4>{title}</h4>
            <p>{text}</p>
        </div>
    </div>
)}

export default KnowledgeItem