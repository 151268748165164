import * as React from 'react'
import Translation from '../../data/translations'

const DesktopIcon: React.FC = () => {
    return(
        <svg className='icon-know' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <title>{Translation.iconDesktop}</title>
            <circle id='top' cx="12" cy="12" r="12" fill="#222222" />
            <path
                d="M6.285714284876974,6.12244902179151 v11.591836517100433 h11.591836517100433 v-11.591836517100433 h-11.591836517100433 zm10.625850140675396,2.897959129275108 v3.380952317487626 h-4.346938693912662 v-3.380952317487626 h4.346938693912662 zm-5.312925070337698,0 v3.380952317487626 h-4.346938693912662 v-3.380952317487626 h4.346938693912662 zm-4.346938693912662,7.727891011400288 v-3.380952317487626 h4.346938693912662 v3.380952317487626 h-4.346938693912662 zm5.312925070337698,0 v-3.380952317487626 h4.346938693912662 v3.380952317487626 h-4.346938693912662 z"
                id="svg_1" className="selected" fill="#ffffff" fill-opacity="1"/>
        </svg>
    )
}

export default DesktopIcon