import * as React from 'react'
import IconLink from "../icons/IconLink"
import {IconType} from "../../interfaces"

const FooterIconPanel: React.FC = () => (
    <div>
        <IconLink type={IconType.linkedInWhite} />
        <IconLink type={IconType.githubWhite} />
    </div>
)

export default FooterIconPanel