import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'
import theme from '../../styles/theme'

const SectionImage: React.FC = () => {

    const { file } = useStaticQuery(
        graphql`
            query {
                file(relativePath: { eq: "home-office.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )

    return (
    <BackgroundImage
        Tag="section"
        className="section-image"
        fluid={file.childImageSharp.fluid}
        backgroundColor={theme.color.black.regular}
    />
)}

export default SectionImage