import * as React from 'react'
import IconLink from "./IconLink"
import {IconType} from "../../interfaces"

const IconPanel: React.FC = () => (
    <div>
        <IconLink type={IconType.linkedIn} />
        <IconLink type={IconType.github} />
    </div>
)

export default IconPanel