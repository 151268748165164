import * as React from 'react'

import Section from "./Section"
import {SectionType} from "../../interfaces"
import KnowledgeList from "../knowledge/KnowledgeList"
import SectionContentTitle from "./SectionContentTitle"
import Translation from '../../data/translations'

const SectionServices: React.FC = () => {
    const type = SectionType.color

    return (
    <Section type={type} id='services' title={Translation.sectionTitleMyServices} titleOnRight={true}>
        <div className='clearfix mt4 mb4'>
            <div className='sm-col sm-col-12 p2'>
                <SectionContentTitle text={Translation.knowledge} type={type} />
            </div>
            <div className='sm-col sm-col-12 p2'>
                <KnowledgeList />
            </div>
        </div>
    </Section>
)}

export default SectionServices