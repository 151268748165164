import React from "react"

import Layout from "../components/layout/Layout"
import Content from "../components/content/Content"

const IndexPage = () => (
  <Layout>
    <Content/>
  </Layout>
)

export default IndexPage
