import * as React from 'react'

type Props = {
    title?: string
}

const WelcomeText: React.FunctionComponent<Props> = ({
                                                         title = '',
                                                     }) => (
    <div>
        <h1 className='section-title text-primary center'>{title}</h1>
    </div>
)

export default WelcomeText