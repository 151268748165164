import * as React from 'react'

import {WorkItemData} from "../../interfaces"
import WorkItemTags from "./WorkItemTags"

const WorkItem: React.FC<WorkItemData> = ({
         employer,
         position,
          text,
          year,
          tags}) => {

    return (
        <li className='timeline-item'>
            <div className='timeline-year'>
                <span >{year}</span>
            </div>
            <span className='timeline-dot'></span>
            <h3 className='text-uppercase'>{employer}</h3>
            <p className='text-gray'>{position}</p>
            <p className='text-graydark'>{text}</p>
            <WorkItemTags tags={tags} />
        </li>
    )}

export default WorkItem