import * as React from 'react'

type Props = {
    tags: string[]
}

const WorkItemTags: React.FC<Props> = ({tags}) => {

    if(tags == null || tags.length === 0) return null

    return (
        <div>
            {tags.map(tag => {
                return <span key={tag} className='work-tags'>{tag}</span>
            })}
        </div>
    )}

export default WorkItemTags