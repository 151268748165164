import * as React from 'react'
import {SectionType} from "../../interfaces"

type Props = {
    type: SectionType,
    text: string
}

const headingClassNames = (type: SectionType) : string => {
    let classNames: string[] = [];

    switch (type) {
        case SectionType.white:
            classNames.push('text-black')
            break
        case SectionType.black:
            classNames.push('text-white')
            break;
    }

    classNames.push('text-uppercase');
    return classNames.join(' ')
}

const SectionContentTitle: React.FC<Props> = ({type, text}) => {

    const classNames = headingClassNames(type)

    return (
    <h3 className={classNames}>{text}</h3>
)}

export default SectionContentTitle