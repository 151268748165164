import * as React from 'react'
import styled from 'styled-components';

import dataService from '../../services/DataService'
import {WorkItemData} from "../../interfaces"
import WorkItem from "./WorkItem"

const WorkList: React.FC = () => {

    const workDataItems = dataService.getWorkData()
    const workItems = workDataItems.map((item: WorkItemData) => (
            <WorkItem
                key={item.position + item.year}
                employer={item.employer}
                position={item.position}
                text={item.text}
                year={item.year}
                tags={item.tags}/>
    ))

    return(
        <div className='clearfix'>
            <WorkListWrapper className='sm-col sm-col-12 timeline'>
                <ul>
                    {workItems}
                </ul>
            </WorkListWrapper>
        </div>
    )}

const WorkListWrapper = styled.div`
    padding-left: 3.125rem;
    margin-top: 0.8rem;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: relative;
        transition: all 0.5s linear;
        top: 0;
    }
    ul::before {
        content: "";
        display: block;
        width: 0;
        height: 100%;
        border: 1px solid ${props => props.theme.color.black.regular};
        position: absolute;
        top: 0;
        left: 30px;
    }
    .timeline {
        padding-left: 50px;
    }
`

export default WorkList