import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'
import WelcomeText from "../welcome/WelcomeText"
import Translation from '../../data/translations'
import theme from '../../styles/theme'

const className = 'clearfix'

const BackgroundSection : React.FC = () => {

    const { file } = useStaticQuery(
        graphql`
            query {
                file(relativePath: { eq: "person.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )

    return (
        <BackgroundImage
            Tag="section"
            className={className}
            fluid={file.childImageSharp.fluid}
            backgroundColor={theme.color.primary}
        >
            <div className='full-screen max-width-4 mx-auto'>
                <div className='clearfix'>
                    <div className='sm-col sm-col-12 md-col-9'>
                        <div className='clearfix mt4'>
                        <div className='sm-col sm-col-12 md-col-6 p2 center'>
                            <WelcomeText title={Translation.title} />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundImage>
)}

const SectionWelcomeBackground = styled(BackgroundSection)`
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  min-height: ${props => props.theme.screen.sectionMinHeight};
`

export default SectionWelcomeBackground