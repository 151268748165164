import * as React from 'react'
import Section from "./Section"
import {SectionType} from "../../interfaces"
import {Contacts} from '../../data/constants'
import FooterIconPanel from "../footer/FooterIconPanel"
import Translation from '../../data/translations'

const SectionContact: React.FC = () => {
    const mailHyperlink = `mailto:${Contacts.Email}`

    return (
        <Section type={SectionType.blackWithBorder} id='contact' title={Translation.sectionTitleContact} titleOnRight={true}>
            <div className='clearfix text-gray mt4 mb4'>
                <div className='sm-col sm-col-12 md-col-6 p2'>
                    <h3>{Translation.contactTitle}</h3>
                        <p>Email : <a className="text-gray" href={mailHyperlink}>{Contacts.Email}</a></p>
                </div>
                <div className='sm-col sm-col-12 md-col-6 p2'>
                    <h3>{Translation.contactGetInTouch}</h3>
                    <FooterIconPanel />
                </div>
            </div>
        </Section>
)}

export default SectionContact