import * as React from 'react'
import styled from 'styled-components';

import theme from '../../styles/theme'
import {SectionType} from "../../interfaces"
import SectionTitle from "./SectionTitle"

type Props = {
    id: string,
    title: string,
    type: SectionType,
    backgroundImage?: string,
    fullScreen?: boolean,
    titleOnRight?: boolean,
    shortOnMobile?: boolean
}

function getSectionClassNames(type: SectionType): string{
    let classNames = ['clearfix']

    switch (type) {
        case SectionType.black:
            classNames.push(theme.colorClass.bgBlack)
            break
        case SectionType.blackWithBorder:
            classNames.push(theme.colorClass.bgBlack)
            classNames.push('border-top-gray')
            break
        case SectionType.white:
            classNames.push(theme.colorClass.bgWhite)
            break
        case SectionType.color:
            classNames.push(theme.colorClass.bgPrimary)
            break
        case SectionType.image:
            classNames.push('image')
            break
        default:
            classNames.push(theme.colorClass.bgBlack)
    }

    return classNames.join(' ')
}

const imageCss = (type: SectionType, backgroundImage: string) => `
    ${type === SectionType.image && `
        background-image: url(images/${backgroundImage}_ld.jpg)
        background-image: -webkit-image-set(  url(images/${backgroundImage}_ld.jpg) 1x, url(images/${backgroundImage}_ld_2x.jpg) 2x  )
        background-image: image-set(url(images/${backgroundImage}_ld.jpg) 1x, url(images/${backgroundImage}_ld_2x.jpg) 2x  )
    `}
`

const setHeightForMobile = (shortOnMobile: boolean) => shortOnMobile ? 'height: 400px;' : ''

const Section: React.FC<Props> = ({
              children,
              id,
              title,
              type,
              backgroundImage,
              fullScreen= false,
              titleOnRight= false,
              shortOnMobile = false
          }) => {

    const sectionClassName = getSectionClassNames(type)

    let containerClassName = 'max-width-4 mx-auto'
    if(fullScreen){
        containerClassName += ' full-screen'
    }

    const showTitle = title !== ''

    let titleColClassName = 'sm-col-12 md-col-3 p2 '
    titleColClassName += titleOnRight ? 'sm-col-right' : 'sm-col'

    return (
    <SectionWrapper
        id={id}
        className={sectionClassName}
        type={type}
        backgroundImage={backgroundImage}
        shortOnMobile={shortOnMobile}
        >
        <div className={containerClassName}>
            <div className='clearfix'>
                {showTitle &&
                    <div className={titleColClassName}>
                        <SectionTitle title={title} type={type}/>
                    </div>
                }
                <div className='sm-col sm-col-12 md-col-9'>
                    {children}
                </div>
            </div>
        </div>
    </SectionWrapper>
)}

const SectionWrapper = styled.section<{
    type: SectionType,
    backgroundImage: string,
    shortOnMobile: boolean}>`
    
    .image {
        ${props => imageCss(props.type, props.backgroundImage)}
        background-color: ${props => props.theme.color.black.regular};
        background-repeat: no-repeat;
        background-position: center top;
        width: 100vw;
        min-height: ${props => props.theme.screen.sectionMinHeight};
    }
    @media (max-width: ${props => props.theme.screen.sm}) {
        .image {
            ${props => imageCss(props.type, props.backgroundImage)}
            ${props => setHeightForMobile(props.shortOnMobile)}
        }
`

export default Section