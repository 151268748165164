import * as React from 'react'
import SectionWelcomeBackground from "../section/SectionWelcomeBackground"
import SectionAboutMe from "../section/SectionAboutMe"
import SectionServices from "../section/SectionServices"
import SectionStory from "../section/SectionStory"
import SectionContact from "../section/SectionContact"
import SectionSkills from "../section/SectionSkills"
import SectionImage from "../section/SectionImage"

const Content: React.FC = () => (
    <div>
        <SectionWelcomeBackground/>
        <SectionAboutMe />
        <SectionServices />
        <SectionSkills />
        <SectionStory />
        <SectionImage />
        <SectionContact />
    </div>
)

export default Content